<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/order_manage' }">订单列表</el-breadcrumb-item>
            <el-breadcrumb-item>订单发货</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button  type="primary" plain @click="goBack">返回</el-button>
      </el-row>
      <el-row class="basic-info">
         <el-row class="title"><b>收货信息</b></el-row>
         <el-row class="basic-top">
            <el-col :span="12">
               <el-row class="m-bottom-10">
                  <p><span>收货人：</span>{{  }}</p>
               </el-row>
               <el-row>
                  <p><span>手机号：</span>{{  }}</p>
               </el-row>
            </el-col>
            <el-col :span="12">
               <el-row class="m-bottom-10">
                  <p><span>收货地址：</span>{{mobile}}</p>
               </el-row>
            </el-col>
         </el-row>
      </el-row>
      <el-row class="basic-info">
         <el-row class="title"><b>商品信息</b></el-row>
         <el-row class="basic-top">
            <el-table
                ref="mainTable"
                :data="tableData"
                :stripe="true"
                >
               <el-table-column label="商品信息" min-width="150">
                  <template slot-scope="scope">
                     <el-row class="member-info">
                        <img :src="scope.row.member.headPortrait" alt="">
                        <el-row>
                           <p>会员编号：{{ scope.row.member.id }}</p>
                           <p>会员昵称：{{ scope.row.member.nickName }}</p>
                           <p>会员等级：{{ scope.row.member.level }}</p>
                        </el-row>
                     </el-row>
                  </template>
               </el-table-column>
               <el-table-column label="价格（元）" min-width="140">
                  <template slot-scope="scope">
                     <p>￥{{ scope.row.orderNo }}</p>
                  </template>
               </el-table-column>
               <el-table-column prop="orderStatus" label="数量"></el-table-column>
               <el-table-column label="小计">
                  <template slot-scope="scope">
                     <p>{{ scope.row.orderItems.length }}</p>
                  </template>
               </el-table-column>
            </el-table>
            <el-row>
               <p><span>商品金额:</span><span>运费金额:￥0.00</span><span>优惠金额:￥0.00</span></p>
               <p><span>支付方式:支付宝支付</span><span>应付金额:￥123.00</span></p>
            </el-row>
         </el-row>
      </el-row>
      <el-row class="basic-info">
         <el-row class="title"><b>订单配送</b></el-row>
         <el-row class="basic-top">
            <el-row><span>配送方式：</span>快递邮寄</el-row>
            <el-row>
               <span>配送方式：</span>
               <el-select class="width-200 m-right-10" ><el-option></el-option></el-select>
            </el-row>
            <el-row><span>配送方式：</span><el-input class="width-200" ></el-input></el-row>
         </el-row>
      </el-row>
      <el-row>
         <el-button type="primary" >发货</el-button>
         <el-button type="primary"  plain>返回</el-button>
      </el-row>
   </section>
</template>

<script>
export default {
   data(){
      return{
         tableData: []
      }
   },
   mounted() {
   },
   methods: {
      // 返回上页
      goBack(){
         this.$router.go(-1)
      }
   }
}
</script>

<style scoped lang="scss">
.cont { height: auto }
.title{
   border-bottom: #e5e6e6 solid 1px; padding: 25px 0 10px 20px;
   font-size: 16px;
}
/* 基本信息 */
.basic-info{
   box-shadow: 0 0 8px #DCE7F9; padding: 0 0 20px 0; color: #222222;
   margin: 0 0 10px 0;
   span{ color: #9c9c9c }
   .basic-top{
      padding: 20px 100px; line-height: 30px;
   }
}
</style>

